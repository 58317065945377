import {getSearchParams} from "../../helpers/linkHelper";
import Page from "../../containers/page";
import React, { Component } from 'react'
import ErrorModal from "../../components/errorModal";
import {Container} from "reactstrap";
import FormBuilder from "../../components/forms/formBuilder";
import {API_ENDPOINT} from "../../constants";

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            menu: [],
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
                console.log(JSON.stringify(this.state.initialValues))

            })
        }
    }
    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.page) {
            this.setState({ lastPage: this.props[0].location.state.page })
        }
        this.get()
    }
    insertOrUpdate = (data) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                if (this.props[0].match.params.id == 'new') {
                    fetch(API_ENDPOINT + '/data/workUnits/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.error) {
                                this.setState({ error: result.error })
                            } else {
                                this.props[0].history.push('/workUnits/list')

                            }
                        })
                } else {
                    fetch(API_ENDPOINT + '/data/workUnits/' + this.props[0].match.params.id, {
                        method: 'PATCH',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.error) {
                                this.setState({ error: result.error })
                            } else {
                                this.props[0].history.push({
                                    pathname: '/workUnits/list',
                                    state: { page: this.state.lastPage }
                                })

                            }
                        })
                }
            }
        )
    }
    render() {

        return (<div className='page'>
            {
                this.state.error ?
                    <ErrorModal
                        isOpen={this.state.error}
                        toggle={() => this.setState({error: null})}
                        text={this.state.error}
                    />
                    :
                    null
            }
            <Container className='dashboard'>
                <FormBuilder

                    onSubmit={(data) => this.insertOrUpdate(data)}
                    initialValues={this.state.initialValues}

                    fields={[
                        {
                            type: 'row',
                            children: [
                                {
                                    type: 'col',
                                    width: {
                                        lg: 9
                                    },
                                    children: [{
                                        type: 'div',
                                        className: 'main-form',
                                        children: [{
                                            type: 'row',
                                            children: [
                                                //HEADER
                                                {//naslov forme
                                                    type: 'col',
                                                    width: {
                                                        lg: 9, sm: 9, xs: 9
                                                    },
                                                    children: [
                                                        {
                                                            type: 'div',
                                                            className: 'form-header',
                                                            children: [
                                                                {
                                                                    type: 'h5',
                                                                    className: 'component-header',
                                                                    text: 'Radna jedinica'
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },

                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12, sm: 12, xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'workUnit',
                                                            label: 'Naziv Radne jedinice'
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12, sm: 12, xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'submit-button',
                                                            className: 'main-form-button button-1',
                                                            text: this.props[0].match.params.id != 'new' ? 'Izmjeni radnu jedinicu' : 'Dodaj radnu jedinicu'
                                                        }
                                                    ]
                                                },

                                            ],


                                        }]
                                    }
                                    ]
                                },
                            ]
                        }
                    ]}
                ></FormBuilder>
            </Container>
        </div>)
    }
}

export default Page(Form)
