export const getSearchParams = (search, initialValues={}) => {
    let brokenParams = search.replace('?', '').split('&');
    let params = initialValues;
    for (let i = 0; i < brokenParams.length; i++) {
        params[brokenParams[i].split('=')[0]] = decodeURIComponent(brokenParams[i].split('=')[1]);
    }

    return params;
}

export const generateSearchLink = (search, initialValues={}, name, value, restart=false) => {
    let params = getSearchParams(search, initialValues);
    if (restart){
        params = initialValues
    }

    if (!value) {
        delete params[name];
    } else {
       
            params[name] = encodeURI(value);
        
    }

    let paramsGroup = [];
    let urlQ = new URLSearchParams();

    for (var key in params) {
        if (params.hasOwnProperty(key) && params[key] && typeof params[key] != 'undefined' && key) {
            paramsGroup.push(`${key}=${params[key]}`)
            urlQ.set(key, params[key]);
        }
    }


    return `?${urlQ.toString()}`;
}


export const generateSearchLinkMultiple = (search, initialValues={}, fields, restart=false) => {
    let params = getSearchParams(search, initialValues);
    if (restart){
        params = initialValues
    }

    for(let i=0;i<fields.length;i++){
        if (!fields[i].value) {
            delete params[fields[i].name];
        } else {
            params[fields[i].name] = fields[i].value;
        }
    }

    let paramsGroup = [];
    for (var key in params) {
        if (params.hasOwnProperty(key) && params[key] && typeof params[key] != 'undefined' && key) {
            paramsGroup.push(`${key}=${params[key]}`)
        }
    }


    return `?${paramsGroup.join('&')}`;
}
