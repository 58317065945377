import React, { Component } from 'react'
import Page from '../../containers/page'

import { Container, Row, Col, Input, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import Search from '../../components/search'
import { Link } from 'react-router-dom'

import ListBuilder from '../../components/listBuilder'
import DeleteModal from '../../components/deleteModal'
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../../helpers/linkHelper'
import { API_ENDPOINT } from '../../constants'

import garabageIcon from '../../assets/svg/garabage.svg'
import userIcon from '../../assets/svg/user-icon.svg'
import notification from '../../assets/svg/notification.svg'
import profile from '../../assets/svg/profile-image.svg'
import moreIcon from '../../assets/svg/more.svg'
import map from '../../assets/svg/map.svg'
import copyIcon from '../../assets/svg/copy_icon.svg'
import editIcon from '../../assets/svg/edit.svg'

import fastEditIcon from '../../assets/svg/fast_edit.svg'



import Toggle from '../../components/forms/fields/toggle'
import FormBuilder from '../../components/forms/formBuilder'

class List extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            newsData: [],
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.page) {
            this.updateParams('page', this.props[0].location.state.page)
        } else {
            this.get()

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }

    delete = (id) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                fetch(API_ENDPOINT + '/data/shop/articles/' + id, {
                    method: 'DELETE',
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.get()
                    })
            }
        )
    }
    updateShopArticle = (data) => {
        console.log(data)
        if (data && data._id) {
            let obj = {
                description: data.description,
                price: data.price
            }
            fetch(API_ENDPOINT + '/data/shop/articles/' + data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    Authorization:
                        typeof localStorage !== 'undefined'
                            ? `Bearer ${localStorage.getItem('authToken')}`
                            : null,
                },
                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((result) => {
                    this.setState({ fastEdit: null }, () => {
                        this.get()
                    })

                })

        }

    }

    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }


        return (
            <div className='page'>
                <Container className='dashboard'>
                    <Row>
                        <Col lg='12'>
                            <Search updateParams={this.updateParams} />
                            <div className='panel'>
                                <div className='main-table-header'>
                                    <h5 className='component-header'>Lista</h5>
                                    <button className='add-news'>
                                        <Link to='/shop/articles/new'>
                                            Dodaj artikal
                                        </Link>
                                    </button>
                                </div>

                                <ListBuilder
                                    loading={this.state.loading && false}
                                    total={this.state.total}
                                    showNumeration={false}
                                    // sortEnabled={true}
                                    fields={[
                                        {
                                            type: 'image', class: 'labelbam', name: 'image', sortLabel: 'Po imenu', allowSort: false,

                                        },
                                        {
                                            type: 'text', class: 'labelbam', name: 'name', sortLabel: 'Po imenu', allowSort: false,
                                        },
                                        {
                                            type: 'text', class: 'labelbam', name: 'finished', sortLabel: 'Po statusu finalizacije', allowSort: true,
                                        },
                                        // {
                                        //     type: 'text', class: 'labelbam', name: 'switch', sortLabel: 'Po imenu', allowSort: true,
                                        // },

                                    ]}
                                    rawItems={this.state.items}
                                    items={


                                        this.state.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                name: item.name,
                                                image: item.images && item.images[0] ? `${API_ENDPOINT}${item.images[0]}` : null,
                                                finished: item.finished ? <div style={{ color: 'red' }}>ZAVRSEN</div> : null,
                                                switch:
                                                    <div className='switch-list-builder-wrap'>
                                                        <Toggle value={item && item.active} onChange={(e) => {
                                                            let status = e.target.checked;
                                                            let id = item._id;
                                                            if (id) {
                                                                let loadingToggle = this.state.loadingToggle ? this.state.loadingToggle : [];
                                                                loadingToggle.push(id)
                                                                this.setState({ loadingToggle }, () => {
                                                                    fetch(API_ENDPOINT + '/data/shop/articles/change/status', {
                                                                        method: 'POST',
                                                                        headers: {
                                                                            'content-type': 'application/json',
                                                                            Authorization:
                                                                                typeof localStorage !== 'undefined'
                                                                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                                                                    : null,
                                                                        },
                                                                        body: JSON.stringify({
                                                                            status: status,
                                                                            id: id
                                                                        })
                                                                    })
                                                                        .then((res) => res.json())
                                                                        .then((result) => {
                                                                            if (result && result.id) {
                                                                                let loadingToggle = this.state.loadingToggle && this.state.loadingToggle.length ? this.state.loadingToggle.filter(el => el !== result.id) : [];
                                                                                this.setState({ loadingToggle })
                                                                            }
                                                                            this.get()
                                                                        })
                                                                })


                                                            }

                                                        }} />
                                                    </div>,
                                            }

                                        })

                                    }
                                    actions={[
                                        {
                                            type: 'functionAction',
                                            render: (item) => <div className='copy-icon-wrap' onClick={() => {
                                                let domen = API_ENDPOINT && API_ENDPOINT.indexOf('localhost') !== -1 ? 'http://localhost:14725' : 'https://interni.euroexpress.ba';
                                                let link = `/interni-shop/${item.categoryLink}/${item._id}`;
                                                navigator.clipboard.writeText(domen + link)
                                                this.setState({ copied: item._id }, () => {
                                                    setTimeout(() => {
                                                        this.setState({ copied: null })
                                                    }, 1000);
                                                })

                                            }}>
                                                {
                                                    this.state.copied === item._id ?
                                                        <div className='tooltip-wrap'>
                                                            Kopirano
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <Isvg src={copyIcon} />
                                            </div>
                                        },
                                        {
                                            type: 'functionAction',
                                            render: (item) =>
                                                <div style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => {
                                                    this.setState({ fastEdit: item })
                                                }}>
                                                    <Isvg style={{ minWidth: 28 }} src={fastEditIcon} />
                                                </div>,
                                        },

                                        {
                                            type: 'functionAction',
                                            render: (item) =>
                                                <Link to={{
                                                    pathname: `/shop/articles/${item._id}`,
                                                    state: { page: params && params.page }
                                                }}
                                                >
                                                    <Isvg style={{ minWidth: 28 }} src={editIcon} />
                                                </Link>,
                                        },
                                        {
                                            component: <Isvg src={garabageIcon} className='edit-icon' />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                        {
                                            type: 'functionAction',
                                            render: (item) => <div>
                                                <div className='switch-list-builder-wrap'>
                                                    <Toggle value={item && item.active} onChange={(e) => {
                                                        let status = e.target.checked;
                                                        let id = item._id;
                                                        if (id) {
                                                            let loadingToggle = this.state.loadingToggle ? this.state.loadingToggle : [];
                                                            loadingToggle.push(id)
                                                            this.setState({ loadingToggle }, () => {
                                                                fetch(API_ENDPOINT + '/data/shop/articles/change/status', {
                                                                    method: 'POST',
                                                                    headers: {
                                                                        'content-type': 'application/json',
                                                                        Authorization:
                                                                            typeof localStorage !== 'undefined'
                                                                                ? `Bearer ${localStorage.getItem('authToken')}`
                                                                                : null,
                                                                    },
                                                                    body: JSON.stringify({
                                                                        status: status,
                                                                        id: id
                                                                    })
                                                                })
                                                                    .then((res) => res.json())
                                                                    .then((result) => {
                                                                        if (result && result.id) {
                                                                            let loadingToggle = this.state.loadingToggle && this.state.loadingToggle.length ? this.state.loadingToggle.filter(el => el !== result.id) : [];
                                                                            this.setState({ loadingToggle })
                                                                        }
                                                                        this.get()
                                                                    })
                                                            })


                                                        }

                                                    }} />
                                                </div>
                                            </div>,
                                        },
                                    ]}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    sortEnabled={true}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    Obrisi <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>
                </Container>

                {
                    this.state.fastEdit ?
                        <Modal isOpen={this.state.fastEdit} size={'xl'} centered={true} className="fast-edit-modal-wrap">
                            <ModalHeader>
                                Artikal: {this.state.fastEdit.name}
                                <div className='close-modal' onClick={() => this.setState({ fastEdit: null })}>&times;</div>
                            </ModalHeader>
                            <ModalBody>
                                <FormBuilder

                                    onSubmit={(data) => this.updateShopArticle(data)}
                                    initialValues={{
                                        price: this.state.fastEdit.price,
                                        description: this.state.fastEdit.description,
                                        _id: this.state.fastEdit._id
                                    }}


                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12
                                                    },
                                                    children: [{
                                                        type: 'div',
                                                        className: 'main-form',
                                                        children: [{
                                                            type: 'row',
                                                            children: [

                                                                // {
                                                                //     type: 'col',
                                                                //     width: {
                                                                //         lg: 12, sm: 12, xs: 12
                                                                //     },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'textarea',
                                                                //             name: 'shortDescription',
                                                                //             label: 'Početni tekst'
                                                                //         },
                                                                //     ]
                                                                // },



                                                                // {
                                                                //     type: 'col',
                                                                //     width: {
                                                                //         lg: 4, sm: 12, xs: 12
                                                                //     },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'originalPrice',
                                                                //             label: 'Prosječna tržišna vrijednost'
                                                                //         },
                                                                //     ]
                                                                // },
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 4, sm: 12, xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'price',
                                                                            label: 'Cijena (KM)'
                                                                        },
                                                                    ]
                                                                },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: {
                                                                //         lg: 4, sm: 12, xs: 12
                                                                //     },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'transportPrice',
                                                                //             label: 'Cijena transporta'
                                                                //         },
                                                                //     ]
                                                                // },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: {
                                                                //         lg: 6, sm: 12, xs: 12
                                                                //     },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'quantity',
                                                                //             label: 'Stanje'
                                                                //         },
                                                                //     ]
                                                                // },

                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 12, sm: 12, xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'html',
                                                                            name: 'description',
                                                                            label: 'Opis',
                                                                            onChange: {}
                                                                        },
                                                                    ]
                                                                },


                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 6, sm: 12, xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'submit-button',
                                                                            className: 'main-form-button button-1',
                                                                            text: 'Izmjeni artikal',
                                                                        }
                                                                    ]
                                                                }

                                                            ]
                                                        }]
                                                    }
                                                    ]
                                                },

                                            ]
                                        }
                                    ]}
                                ></FormBuilder>
                            </ModalBody>
                            <ModalFooter>

                            </ModalFooter>

                        </Modal>
                        :
                        null

                }

            </div>
        )
    }
}

export default Page(List)
