import React, { Component, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import Isvg from 'react-inlinesvg'

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import socketIO from 'socket.io-client'
import moment from 'moment'
import signal from '../assets/svg/signal.svg'
import noSignal from '../assets/svg/no-signal.svg'

import home from '../assets/svg/home.svg'
import devices from '../assets/svg/devices.svg'
import user from '../assets/svg/user.svg'
import log from '../assets/svg/log.svg'
import logout from '../assets/svg/logout.svg'
import secondarylogo from '../assets/svg/secondarylogo.svg'
import logo from '../assets/logo.svg'
import menuIcon from '../assets/svg/icon-menu.svg'
import homeIcon from '../assets/svg/home-icon.svg'
import commentIcon from '../assets/svg/comments-icon.svg'
import administratorIcon from '../assets/svg/administrator-icon.svg'
import moderatorIcon from '../assets/svg/moderator-icon.svg'
import editorIcon from '../assets/svg/editor-icon.svg'
import logoutIcon from '../assets/svg/logout-icon.svg'
import logoBottom from '../assets/svg/logo-bottom.svg'
import arrowDown from '../assets/svg/arrow-down.svg'
import listIcon from '../assets/svg/listnews-icon.svg'
import addIcon from '../assets/svg/addnews-icon.svg'

import listPageIcon from '../assets/svg/s1.svg'
import addPageIcon from '../assets/svg/s2.svg'
import contactIcon from '../assets/svg/s3.svg'
import settingsIcon from '../assets/svg/s5.svg'

moment.locale('sr')

import { API_ENDPOINT } from '../constants'

export class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      show: false,

      menu: false,
      categoryNews: false,
      news: false,
      documents: false,
      pools: false,
      admins: false,
      users: false,
      settings: false,
      shop: false,
      absences: false,
      suggestions: false,
      newEmployees: false,
      deliveryLines: false,
      library: false

    }
  }

  toggleClass = () => {
    const currentState = this.state.active
    this.setState({ active: !currentState })
  }
  toggleMenu = () => {
    const currentState = this.state.show
    this.setState({ show: !currentState })
  }
  logout = () => {

    localStorage.removeItem('authToken');
    this.props[0].history.push('/login');
    window.location.reload(false)

  }

  componentDidMount() {

    if (this.props.uData && this.props.uData.menuObjects && this.props.uData.menuObjects.length) {
      for (let i = 0; i < this.props.uData.menuObjects.length; i++) {
        if (this.props.uData.menuObjects[i].uniqueID == 1) {
          this.setState({
            categoryNews: true,
            news: true,

          })
        } else if (this.props.uData.menuObjects[i].uniqueID == 2) {
          this.setState({

            documents: true,
            // pools: false,
            absences: true,
            suggestions: true,
            newEmployees: true,
            users: true,
            settings: true

          })
        } else if (this.props.uData.menuObjects[i].uniqueID == 3) {
          this.setState({

            categoryNews: true,
            news: true,
            documents: true,

          })
        } else if (this.props.uData.menuObjects[i].uniqueID == 4) {
          this.setState({
            categoryNews: true,
            news: true,
            deliveryLines: true
          })
        }
      }
    }

    if (this.props.uData && this.props.uData.menu && this.props.uData.menu.length) {
      if (this.props.uData.menu.filter(el => el === 'admin-shop').length) {
        this.setState({
          shop: true
        })
      }
    }





  }


  render() {

    if (!this.props.uData) {
      return null
    }

    return (
      <header>
        <div className='sidebar'>
          <div className='sidebar-content'>

            <div className="sidebar-header">
              <a href="/">
                <div className='logo'>
                  <img src={logo} className='logo-image' />
                </div>
              </a>

              <ul className='sidebar-body'>
                {/* {
                  this.props.uData.super ?
                    <>

                    </>
                    :
                    null
                } */}

                {
                  this.props.uData.super || this.state.shop ?
                    <>
                      <span className="menu-helper content-helper">SHOP</span>
                      <li className='menu__item'>
                        <Link to='/shop/categories/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Kategorije</span>
                        </Link>
                      </li>

                      <li className='menu__item'>
                        <Link to='/shop/categories/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj kategoriju</span>
                        </Link>
                      </li>


                      <li className='menu__item'>
                        <Link to='/shop/articles/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Artikli</span>
                        </Link>
                      </li>

                      <li className='menu__item'>
                        <Link to='/shop/articles/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj Artikal</span>
                        </Link>
                      </li>

                      <li className='menu__item'>
                        <Link to='/shop/orders/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Narudžbe</span>
                        </Link>
                      </li>

                    </>
                    :
                    null
                }





                {
                  this.props.uData.super || this.state.settings ?
                    <>
                      <span className="menu-helper content-helper">SETTINGS</span>
                      <li className='menu__item'>
                        <Link to='/settings' className='menu__link'>
                          <span >
                            <Isvg src={settingsIcon} />
                          </span>
                          <span className='menu__text'>Početna</span>
                        </Link>
                      </li>

                    </>
                    :
                    null
                }

                {
                  this.props.uData.super ?
                    <>
                      <span className="menu-helper content-helper">MENI</span>
                      <li className='menu__item'>
                        <Link to='/menu/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>
                      {/* <li className='menu__item'>
                        <Link to='/menu/sort' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Sortiraj</span>
                        </Link>
                      </li> */}
                      <li className='menu__item'>
                        <Link to='/menu/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>
                    </>
                    :
                    null
                }





                {
                  this.state.categoryNews ?
                    <>
                      <span className="menu-helper content-helper">KATEGORIJE NOVOSTI</span>
                      <li className='menu__item'>
                        <Link to='/news/categories/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>
                      <li className='menu__item'>
                        <Link to='/news/categories/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>

                    </>
                    :
                    null
                }




                {
                  this.state.news ?
                    <>
                      <span className="menu-helper content-helper">NOVOSTI</span>
                      <li className='menu__item'>
                        <Link to='/news/articles/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>
                      <li className='menu__item'>
                        <Link to='/news/articles/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>
                    </>
                    :
                    null
                }



                {
                  this.state.documents ?
                    <>
                      <span className="menu-helper content-helper">DOKUMENTA</span>
                      <li className='menu__item'>
                        <Link to='/documents/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>
                      <li className='menu__item'>
                        <Link to='/documents/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>
                    </>
                    :
                    null
                }

                {
                  this.props.uData.super ?
                    <>
                      <span className="menu-helper content-helper">ANKETE</span>
                      <li className='menu__item'>
                        <Link to='/pools/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>
                      <li className='menu__item'>
                        <Link to='/pools/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>
                    </>
                    :
                    null
                }

                {
                  this.props.uData.super ?
                    <>
                      <span className="menu-helper content-helper">ANKETE SA SLIKAMA</span>
                      <li className='menu__item'>
                        <Link to='/pools-images/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>
                      <li className='menu__item'>
                        <Link to='/pools-images/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>
                    </>
                    :
                    null
                }
                 {
                  this.props.uData.super ?
                    <>
                      <span className="menu-helper content-helper">VIDEO ANKETE</span>
                      <li className='menu__item'>
                        <Link to='/pools-video/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>
                      <li className='menu__item'>
                        <Link to='/pools-video/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>
                    </>
                    :
                    null
                }




                {
                  this.props.uData.super ?
                    <>
                      <span className="menu-helper content-helper">ADMINISTRATORI</span>
                      <li className='menu__item'>
                        <Link to='/admins/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>
                      <li className='menu__item'>
                        <Link to='/admins/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>
                    </>
                    :
                    null
                }




                {
                  this.state.users ?
                    <>
                      <span className="menu-helper content-helper">KORISNICI</span>
                      <li className='menu__item'>
                        <Link to='/users/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>

                      <li className='menu__item'>
                        <Link to='/users/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>

                      <li className='menu__item'>
                        <Link to='/forgotten-password' className='menu__link'>
                          <span >
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Zaboravljene lozike</span>
                        </Link>
                      </li>



                    </>
                    :
                    null
                }
                {
                  this.state.users ?
                    <>
                      <span className="menu-helper content-helper">RADNE JEDINICE</span>
                      <li className='menu__item'>
                        <Link to='/workUnits/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>

                      <li className='menu__item'>
                        <Link to='/workUnits/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>


                    </>
                    :
                    null
                }
                {
                  this.state.users ?
                      <>
                        <span className="menu-helper content-helper">RADNA MJESTA</span>
                        <li className='menu__item'>
                          <Link to='/workPlaces/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                            <span className='menu__text'>Lista</span>
                          </Link>
                        </li>

                        <li className='menu__item'>
                          <Link to='/workPlaces/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                            <span className='menu__text'>Dodaj</span>
                          </Link>
                        </li>
                      </>
                      :
                      null
                }
                {
                  this.state.users ?
                      <>
                        <span className="menu-helper content-helper">SEKTORI</span>
                        <li className='menu__item'>
                          <Link to='/sectors/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                            <span className='menu__text'>Lista</span>
                          </Link>
                        </li>

                        <li className='menu__item'>
                          <Link to='/sectors/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                            <span className='menu__text'>Dodaj</span>
                          </Link>
                        </li>


                      </>
                      :
                      null
                }
                {/*<span className="menu-helper content-helper">CONTACT</span>
                    <li className='menu__item'>
                      <a className='menu__link'>
                        <span>
                          <Isvg src={contactIcon} />
                        </span>
                        <span className='menu__text'>Lista kontakata</span>
                      </a>
    </li>*/}



                {
                  this.state.absences ?
                    <>
                      <span className="menu-helper content-helper">ODSUSTVO</span>
                      <li className='menu__item'>
                        <Link to='/absence' className='menu__link'>
                          <span >
                            <Isvg src={settingsIcon} />
                          </span>
                          <span className='menu__text'>Lista zahtjeva</span>
                        </Link>
                      </li>
                    </>
                    :
                    null
                }
                {
                  this.state.suggestions ?
                    <>
                      <span className="menu-helper content-helper">PRIJEDLOZI</span>
                      <li className='menu__item'>
                        <Link to='/suggestions' className='menu__link'>
                          <span >
                            <Isvg src={settingsIcon} />
                          </span>
                          <span className='menu__text'>Lista prijedloga</span>
                        </Link>
                      </li>
                    </>
                    :
                    null
                }
                {
                  this.state.newEmployees ?
                    <>
                      <span className="menu-helper content-helper">Novi radnici</span>
                      <li className='menu__item'>
                        <Link to='/news/employees/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>
                      <li className='menu__item'>
                        <Link to='/news/employees/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>

                    </>
                    :
                    null
                }

                {
                  this.props.uData.super ?
                    <>
                      <span className="menu-helper content-helper">Interna biblioteka</span>
                      <li className='menu__item'>
                        <Link to='/news/library/list' className='menu__link'>
                          <span>
                            <Isvg src={listPageIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>
                      <li className='menu__item'>
                        <Link to='/news/library/new' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Dodaj</span>
                        </Link>
                      </li>

                    </>
                    :
                    null
                }








                {/* 
                {
                  this.props.uData.menuObjects && this.props.uData.menuObjects.map((item, idx) => {
                    if (item.uniqueID == '1')
                      return (
                        <>
                          <span className="menu-helper content-helper">MJERE PREDOSTROZNISTI</span>
                          <li className='menu__item'>
                            <Link to='/precautions/list' className='menu__link'>
                              <span>
                                <Isvg src={listPageIcon} />
                              </span>
                              <span className='menu__text'>Lista</span>
                            </Link>
                          </li>
                          <li className='menu__item'>
                            <Link to='/precaution/precautions/new' className='menu__link'>
                              <span>
                                <Isvg src={addPageIcon} />
                              </span>
                              <span className='menu__text'>Dodaj</span>
                            </Link>
                          </li>
                        </>
                      )
                  })
                } */}




                {
                  this.state.deliveryLines ?
                    <>
                      <span className="menu-helper content-helper">DOSTAVNE LINIJE</span>

                      <li className='menu__item'>
                        <Link to='/delivery-lines' className='menu__link'>
                          <span>
                            <Isvg src={addPageIcon} />
                          </span>
                          <span className='menu__text'>Izmjena</span>
                        </Link>
                      </li>
                    </>
                    :
                    null
                }

                {/* {
                  this.props.uData.super ?
                    <>
                      <span className="menu-helper content-helper">LOGS</span>
                      <li className='menu__item'>
                        <Link to='/logs' className='menu__link'>
                          <span >
                            <Isvg src={settingsIcon} />
                          </span>
                          <span className='menu__text'>Lista</span>
                        </Link>
                      </li>

                    </>
                    :
                    null
                } */}



                <span className="menu-helper content-helper"></span>

                <li className='menu__item'>
                  <div className='menu__link' onClick={this.logout}>
                    <span className="logout-svg">
                      <Isvg src={logoutIcon} />
                    </span>
                    <span className='menu__text'>Odjavi se</span>
                  </div>
                </li>
              </ul>
            </div>


            <div className='sidebar-footer'>
              <Isvg src={logoBottom} className='footer-icon' />
            </div>

          </div>
        </div>
      </header>
    )
  }
}

export default Header
